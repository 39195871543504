.file-icon-image { color: #f39c12; }      // Orange for images
.file-icon-video { color: #e74c3c; }      // Red for videos
.file-icon-audio { color: #9b59b6; }      // Purple for audio files
.file-icon-pdf { color: #c0392b; }        // Dark red for PDF files
.file-icon-word { color: #2a5298; }       // Blue for Word documents
.file-icon-excel { color: #27ae60; }      // Green for Excel files
.file-icon-powerpoint { color: #d35400; } // Orange for PowerPoint files
.file-icon-text { color: #34495e; }       // Dark gray-blue for text files
.file-icon-archive { color: #e67e22; }    // Orange for compressed archives
.file-icon-code { color: #2980b9; }       // Blue for code files

.file-icon:hover {
    opacity: 0.8;
}


.folder-icon {
    color: #f39c12; // Default orange for generic folders
}

// Colors for specific folder types
.folder-icon-important { color: #e74c3c; }    // Red for important folders
.folder-icon-shared { color: #3498db; }       // Blue for shared folders
.folder-icon-private { color: #9b59b6; }      // Purple for private folders
.folder-icon-archive { color: #e67e22; }      // Dark orange for archived folders
.folder-icon-project { color: #27ae60; }      // Green for project folders

// Optional hover effect for folder icons
.folder-icon:hover {
    opacity: 0.8;
}