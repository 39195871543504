@import './variable.scss';
@import './default-variable.scss';

.input-uppercase {
    text-transform: uppercase;
}

.input-lowercase {
    text-transform: lowercase;
}

.su-ecriture-sum{
    font-weight: bold;
    text-align: right;

    .table-sum-success {
        color:#fff
    }

    .table-sum-danger {
        color:red
    }
}

.image-display {
    pointer-events: none;
    .modal-backdrop {
        pointer-events: none;
    }
}

/* SHORTCUT */
.image-non-saisie {
    background-color: $no-saisie-color;
}

.image-imputee {
    background-color: $imputee-color;
}

.image-valider-ctr-su {
    background-color: $valider-ctrl-color;
}

.image-ecriture-su {
    background-color: $ecriture-color;
}

.list-image-su {
    .image-non-saisie {
        background-color: $no-saisie-color;
    }
    
    .image-imputee {
        background-color: $imputee-color;
    }
    
    .image-valider-ctr-su {
        background-color: $valider-ctrl-color;
    }

    .image-ecriture-su {
        background-color: $ecriture-color;
    }

    .list-group-item {
        color: black;
        font-weight: 900;
        // padding-left: 10px;
        // padding-right: 10px;
    }

    .list-group-item.active {
        border: 2px solid red;
    }    
}

.form-saisie-su {
    .mb-half {
        margin-bottom: 0.5rem;
    }

    .input-group-text {
        padding: 0.5rem 0.5rem;
    }

    .form-check .form-check-input {
        float: initial;
        margin-left: -1.785rem;
    }
}

.tiers-modal-offcanvas {
    min-width: 50vh;

    .offcanvas-body {
        overflow: unset;
    }
}

// ** Screen Size

.custom-saisie-panel {
    padding: 0px 15px 0px 15px !important;
    height: $screen-size;
    overflow-y: scroll;
}

.image-su {
    height: $image-view;
}

// ** OCR style

.ocr-container {
    height: $image-ocr-view;
}

.ocr-button-param {
    position: absolute;
    top: -11% !important;
    border: solid 1px black;
    color: black;
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}

.sticky-container-su {
    background-color: white; /* Optional: to ensure it stands out */
    // z-index: 1000; /* Optional: to ensure it stays above other content */
    transition: all 0.5s ease; /* Optional: for smooth transition */
  }
  
  .fixed-su {
    position: fixed;
    width: auto; /* Ensure the width matches the parent container */
  }
  
  .placeholder-su {
    height: 0; /* Placeholder to maintain the layout height */
  }
  
  .content-su {
    height: 1000px; /* Simulate a large amount of content */
  }
  
  .spacer-su {
    height: 200px; /* Spacer to create scrollable content */
    background-color: lightgray; /* Optional: to visualize the spacer */
  }
  

/* global style */
.view-inline-spacing {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // align-items: center;
    & > * {
        margin-right: 1.5rem;
    }
}

/* table style */
// style for total-row

@mixin total-row-text($font-size,$font-weight,$font-family) {
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    font-family: $font-family !important;
}

.table-total-row{
    @include total-row-text($su-table-font-size, bold, inherit)
}

.custom-table {
    min-width: unset!important;
    table-layout: fixed;

    thead {
        z-index: 1020;
        border-collapse: separate;
        text-wrap: nowrap;
    }

    thead,
    tfoot {
        th {
            vertical-align: top;
            text-align: center;
            letter-spacing: 0.5px;
        }
    }
    
    .MuiTableRow-root:hover {
        box-shadow: none;
    }

    .tableHead{
        user-select: none;
        .header-title {
            align-items: center;
            display: flex;
            flex-direction: row;
            -webkit-box-pack: start;
            justify-content: center;
            position: relative;
            width: 100%;
        }
    }

    .tableCell {
        text-wrap: nowrap;
        overflow: hidden;
        // border: 1px solid #ebe9f1 !important;
        // border: 1px solid #ebe9f1 !important;
        // border: 1px solid #000 !important;
        border-width: 1px 1px 1px 1px;
        padding: 10px 5px 10px 5px;
        &.resizable{
            position: relative;
        }
        &.emptyCell{
            width: "auto"
        }
    }

    .resizeLine{
        position: absolute; 
        height: 100%;
        width: 4px;
        top: 0;
        right: -2px;
        cursor: col-resize;
        &:hover{
            background-color: #0AA1DD;
        }
        &:active {
            background-color: #0AA1DD;
        }
    }

    tbody {
        td {
            white-space: nowrap;
            overflow: hidden;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.43;
            letter-spacing: 0.01071em;
            display: table-cell;
            vertical-align: inherit;
            text-align: center;
            padding: 16px;
            color: rgba(0, 0, 0, 0.87);
            // text-overflow: ellipsis;
        }
        .td-div {
            color: rgba(0, 0, 0, 0.87);
            cursor: text;
            display: inline;
            position: relative;
            // font-size: 1rem;
            box-sizing: border-box;
            align-items: center;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.1876em;
            letter-spacing: 0.00938em;
        }

        .tablecell-disabled {
            background-color: #efefef;
        }

        input {
            // font: inherit;
            // color: currentColor;
            // width: 100%;
            // border: 0;
            // height: 1em;
            // // border-bottom: solid 1px $calendar;
            // border-radius: 0;
            // margin: 0;
            // display: block;
            // // padding: 6px 0 7px;
            // margin: 6px 0px 6px -5px;
            // min-width: 0;
            // background: none;
            
            // animation-name: mui-auto-fill-cancel;
            // letter-spacing: inherit;
            // animation-duration: 10ms;
            // -webkit-tap-highlight-color: transparent;
            font: inherit;
            color: currentColor;
            border: none;
            background: none;
            outline: none;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            font-size: 1rem;
            padding: 10px 2px 10px 2px!important;
            margin: 0;
            animation-name: mui-auto-fill-cancel;
            letter-spacing: inherit;
            animation-duration: 10ms;
            -webkit-tap-highlight-color: transparent;
        }

        .td-input:focus-visible {
            outline: none;
        }
        .action-button-column {
            display: flex;
            justify-content: space-evenly;
        }

        .selected-row {
            // background: #FFF0DB;
            // .tableCell {
            //     padding: 5px 0px 5px 0px;
            // }
            background: none;
            .tableCell {
                // padding: 5px 0px 5px 0px;
                padding: 0;
            }
            .td-all-display {
                overflow: visible;
            }
        }

        .subrow {
            border-width: 1px 1px 1px 1px;
            overflow: visible;
        }
    }
}

.custom-table-edit {
    min-width: unset!important;

    thead {
        z-index: 1020;
        border-collapse: separate;
        text-wrap: nowrap;
    }

    thead,
    tfoot {
        th {
            z-index: 1020;
            border-collapse: separate;
            text-wrap: nowrap;
            vertical-align: top;
            text-align: center;
            letter-spacing: 0.5px;
        }
    }
    
    .MuiTableRow-root:hover {
        box-shadow: none;
    }

    .tableHead{
        user-select: none;
    }
    .tableCell {
        text-wrap: nowrap;
        overflow: hidden;
        // border: 1px solid #ebe9f1 !important;
        // border: 1px solid #ebe9f1 !important;
        // border: 1px solid #000 !important;
        border-width: 1px 1px 1px 1px;
        padding: 10px 5px 10px 5px;
        &.resizable{
            position: relative;
        }
        &.emptyCell{
            width: "auto"
        }
    }

    .resizeLine{
        position: absolute; 
        height: 100%;
        width: 4px;
        top: 0;
        right: -2px;
        cursor: col-resize;
        &:hover{
            background-color: #0AA1DD;
        }
        &:active {
            background-color: #0AA1DD;
        }
    }

    tbody {
        td {
            white-space: nowrap;
            overflow: hidden;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.43;
            letter-spacing: 0.01071em;
            display: table-cell;
            vertical-align: inherit;
            text-align: center;
            padding: 16px;
            color: rgba(0, 0, 0, 0.87);
            // text-overflow: ellipsis;
        }
        .td-div {
            color: rgba(0, 0, 0, 0.87);
            cursor: text;
            display: inline;
            position: relative;
            // font-size: 1rem;
            box-sizing: border-box;
            align-items: center;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.1876em;
            letter-spacing: 0.00938em;
        }

        .tablecell-disabled {
            background-color: #efefef;
        }

        .td-input {
            // font: inherit;
            // color: currentColor;
            // width: 100%;
            // border: 0;
            // height: 1em;
            // // border-bottom: solid 1px $calendar;
            // border-radius: 0;
            // margin: 0;
            // display: block;
            // // padding: 6px 0 7px;
            // margin: 6px 0px 6px -5px;
            // min-width: 0;
            // background: none;
            
            // animation-name: mui-auto-fill-cancel;
            // letter-spacing: inherit;
            // animation-duration: 10ms;
            // -webkit-tap-highlight-color: transparent;
            font: inherit;
            color: currentColor;
            border: none;
            background: none;
            outline: none;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            font-size: 1rem;
            padding: 10px 5px 10px 5px!important;
            margin: 0;
            animation-name: mui-auto-fill-cancel;
            letter-spacing: inherit;
            animation-duration: 10ms;
            -webkit-tap-highlight-color: transparent;
        }

        .td-input:focus-visible {
            outline: none;
        }
        .action-button-column {
            display: flex;
            justify-content: space-evenly;
        }

        .selected-row {
            // background: #FFF0DB;
            // .tableCell {
            //     padding: 5px 0px 5px 0px;
            // }
            background: none;
            .tableCell {
                // padding: 5px 0px 5px 0px;
                padding: 0;
            }
            .td-all-display {
                overflow: visible;
            }
        }

        .subrow {
            border-width: 1px 1px 1px 1px;
            overflow: visible;
        }
    }
}

// ** custom material react table style
.custom-material-react-table-expanded {
    th:first-of-type,
    td:first-of-type { // Target the first column in the table rows
        width: 3%; 
        min-width: 3%; 
        max-width: 3%; 

        .Mui-TableHeadCell-Content {
            justify-content: left;
            max-width: 3%;
            
        }
    }
}

.custom-material-react-table {
    border-collapse: collapse!important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: rgba(81, 81, 81, 0.5) !important;

    thead {
        th {
            vertical-align: top;
            text-transform: uppercase;
            // font-size: .900rem;
            font-size: .900rem;
            font-weight: bold;
            letter-spacing: .5px;
            text-align: center;
            // background-color: rgb(243, 242, 247);
            // background-color: $calendar;
            background: $gradient-calendar;
            color: #fff;

            .Mui-TableHeadCell-Content {
                justify-content: center;
            }
        }

        tr {
            // background-color: rgb(243, 242, 247);
            // background-color: #19948a;
            background: $gradient-calendar;
            // background-color: $calendar;
            color: #fff;
        }
    }

    tbody {
        tr {
            td {
                *text-wrap: nowrap;
            }
        }
    }

    tfoot {
        tr {
            td {
                font-style: italic;
                font-weight: bold;
                font-size: .857rem;
                // background-color: rgb(243, 242, 247);
                background: $gradient-calendar;
                color: #fff;
            }
        }
    }
    
}

.table-paper {
    box-shadow: none;
}

// .table-container {
//     .MuiTableCell-head div {
//         display: inherit !important;
//     }
// }

.filter-match {
    font-weight: bold;
    color: $calendar;
}

.filter-no-match {
    color: $red;
}

.popover-danger {
    .popover-header {
        background-color: $red;
        border-color: $red;
    }

    .popover-body {
        max-width: 300px;
    }

    .popover-content {
        margin: 0 auto;
    }

    .popover-button-content {
        margin-top: 15px;
    }
}

//** Feather
.feather-action {
    cursor: pointer;
    margin-right: 5px;
}

.btn-icon-action {
    padding: 0!important;
}

.feather-primary {
    color: $calendar;
}

.feather-warning {
    color: $orange;
}

.feather-info {
    color: $blue;
}

//** Avatar
.avatar-primary {
    color:#fff;
    background-color: $default-calendar;
}

.avatar-warning {
    color:#fff;
    background-color: $orange;
}

.avatar-info {
    color:#fff;
    background-color: $blue;
}

// ** Saisie Universelle
.focus-ocr {
    background-color: #FFF0DB!important;
}

.focus-ocr:focus {
    background-color: #FFF0DB!important;
}

.validation-check-success {
    .select__control {
        border-color: $default-calendar !important;
    }
}

.validation-check-danger {
    .select__control {
        border-color: $danger !important;
    }
}

.ocr-danger {
    border-color: $red;
}

.ocr-warning {
    border-color: $orange;
}

.ocr-success {
    border-color: $default-calendar;
}

.input-group-ocr-success {
    &:not(.bootstrap-touchspin):focus-within {
        box-shadow: $input-focus-box-shadow;
        border-radius: $input-border-radius;
        .form-control,
        .input-group-text {
            border-color: $default-calendar;
        }
    }
}

.input-group-ocr-danger {
    &:not(.bootstrap-touchspin):focus-within {
        box-shadow: $input-focus-box-shadow;
        border-radius: $input-border-radius;
        .form-control,
        .input-group-text {
            border-color: $red;
        }
    }
}

.input-group-ocr-warning {
    &:not(.bootstrap-touchspin):focus-within {
        box-shadow: $input-focus-box-shadow;
        border-radius: $input-border-radius;
        .form-control,
        .input-group-text {
            border-color: $orange;
        }
    }
}

.input-group-avatar {
    .MuiAvatar-root {
        background-color: $orange;
        box-sizing: border-box!important;
        font-size: inherit!important;
        height: 35px!important;
        width: 35px!important;
    }

    .avatar-list-custom {
        background-color: $default-calendar;
        box-sizing: border-box!important;
        font-size: inherit!important;
        height: 35px!important;
        width: 35px!important;
    }
}

// ** Modal
.no-backdrop-modal {
    pointer-events: none;
    .modal-backdrop {
        pointer-events: none;
    }
}

.draggable-modal-header {
    cursor:move;

    .modal-title {
        width: 100%;
    }
}

.hello-world {
    border-color: darkmagenta;
}

// ** Bloc
.card-section {
    .card-header-collapse {
        padding: 1rem 0rem 0.5rem 0rem;
        border-width: 0px 0px 1px 0px;
        margin-bottom: 7px;
    }
}

.card-bloc {
    // border-width: 1px 1px 1px 1px;
    // border: 1px ridge rgba(0, 0, 0, 0.6);
    padding: 10px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
    0px 1px 1px 0px rgba(0,0,0,0.14),
    0px 1px 3px 0px rgba(0,0,0,0.12) !important;

    .divider {
        margin: 5px 0px 5px 0px;
    }

    .divider .divider-add i, .divider .divider-add svg {
        height: 1.3rem;
        width: 1.3rem;
        font-size: 1.3rem;
    }
}
.card-bloc-top {
    margin: 0px 0px 25px 0px;
}

.card-bloc-bottom {
    margin: 0px 0px 0px 0px;
}

// ** card pour les écritrues

.ecriture-draggable {
    .draggable-handle {
        cursor: grab;
    }
}

.ecriture-card {
    // border-width: 1px 1px 1px 1px;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.6);
    padding: 10px 10px 10px 10px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
    0px 1px 1px 0px rgba(0,0,0,0.14),
    0px 1px 3px 0px rgba(0,0,0,0.12) !important;

    &:hover {
        // transform: translateY(-2px);
        box-shadow: 0 4px 25px 0 rgba($black, 0.10);
        // border-color: $calendar;
    }

    // ** title
    .card-title {
        padding: 3px;
        font-size: 14px;
        border-bottom: 1px solid #dae1e7;
        margin-bottom: 5px;
    }

    // ** body
    .card-body {
        .table-container {
            margin: 0px 0px 5px 0px;

            .table-inline-view {
                display: flex;
                padding: 0;
            }

            .custom-table,
            .custom-table-edit {
                margin-right: 5px;
            }
        }
        // .table-container-top {
        //     margin: 0px 0px 25px 0px;
        // }

        // .table-container-bottom {
        //     margin: 0px 0px 0px 0px;
        // }
    }

    // ** footer
    .card-footer {
        padding: 10px;
    }
    
    // ** Table
    .custom-table,
    .custom-table-edit {

        .inline-head {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            & > * {
                margin-right: .5rem;
            }
        }
        
        tbody {
            .selected-row {
                background: none;
                .tableCell {
                    // padding: 5px 0px 5px 0px;
                    padding: 0;
                }
                .td-all-display {
                    overflow: visible;
                }

                .form-control {
                    &:focus {
                        &::placeholder {
                            transform: translate(5px);
                            transition: all 0.2s ease;
                        }
                        &:valid,
                        &.is-valid {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}

.ecriture-multiple {
    // // border-width: 1px 1px 1px 1px;
    // margin: 0;
    // border: 1px solid rgba(0, 0, 0, 0.6);
    // padding: 10px 10px 10px 10px;
    // box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
    // 0px 1px 1px 0px rgba(0,0,0,0.14),
    // 0px 1px 3px 0px rgba(0,0,0,0.12) !important;

    // &:hover {
    //     // transform: translateY(-2px);
    //     box-shadow: 0 4px 25px 0 rgba($black, 0.10);
    //     // border-color: $calendar;
    // }

    // ** title
    .card-title {
        padding: 3px;
        font-size: 14px;
        border-bottom: 1px solid #dae1e7;
        margin-bottom: 5px;
    }

    .card-body {
        .table-container {
            margin: 0px 0px 5px 0px;

            .table-inline-view {
                display: flex;
                padding: 0;
            }

            .custom-table,
            .custom-table-edit {
                margin-right: 5px;
            }
        }
        // .table-container-top {
        //     margin: 0px 0px 25px 0px;
        // }

        // .table-container-bottom {
        //     margin: 0px 0px 0px 0px;
        // }
    }

    // ** footer
    .card-footer {
        padding: 10px;
    }
}

.table-multiple {
    max-height: 30vh;
    min-height: .01%;
    overflow-x: auto;
    overflow-y: visible;
}

.table-saisie-mutliple {
    tbody {
        .selected-row {
            // background: #b8e3e9;
            background: rgba(255, 159, 67, 0.25)!important;
        }

        .saved-row {
            background-color: rgba(40, 199, 111, 0.25);
        }

        .invalid-row {
            background-color: rgba(234, 84, 85, 0.25);
        }

        .tableCell {
            border-width: 1px 1px 1px 1px;
            padding: 3px 5px 3px 5px;
        }

        .td-input {
            font-size: inherit;
            padding: 3px 5px 3px 5px;
        }

        .focus-ocr {
            background-color: rgba(255, 159, 67, 0.35)!important;
        }
        
        .focus-ocr:focus {
            background-color: rgba(255, 159, 67, 0.35)!important;
        }
    }
}

.ecriture-card-edited {
    border: 1px solid $calendar;
}

.custom-select-menu {
    position: absolute;
    top: 100%; /* Adjust this value as needed */
    left: 0;
    z-index: 1000; /* Ensure it's above the table */
    width: 100%;
    max-height: 300px; /* Adjust the maximum height */
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.promotion-triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-left: 50px solid red;
    border-bottom: 50px solid transparent;
    color: white;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    transform: translate(-10%, -10%);
}

.promotion-triangle::before {
    content: 'Promo';
    position: absolute;
    top: 10px;
    left: 10px;
    transform: rotate(-45deg);
}

// ** Badge

.action-badge {
    cursor: pointer;
}

.blinking-badge {
    animation: blink 1.5s infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.75;
    }
}

@keyframes scale-bounce {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

.joker-badge {
    background-color: transparent !important;
    top: 0!important;
    left: -2.5em !important;
    right: inherit !important;
    animation: joker-blink 1s infinite;

    .joker-icon {
        color: #000000;
    }
}

@keyframes joker-blink {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

.scale-bounce-animation {
    animation: scale-bounce 0.6s ease-in-out infinite;
}

.date-disabled {
    background-color: #efefef!important;
}

.infoperdos-content {
    border-left: solid;
    border-width: 0.5px!important;
    border-color: rgba(180, 185, 190, 0.6)!important;

    .custom-material-react-table {
        border: none!important;
        border-style: none;
        tbody {
            tr {
                border-width: 0!important;
                td {
                    border-bottom: solid;
                    border-width: 0 0 0.5px 0!important;
                    border-color: rgba(180, 185, 190, 0.6)!important;
                }
            }

            tr:hover {
                box-shadow: none;
            }
        }
    }
}

.content-editable-field {
    font: inherit;
    color: currentColor;
    border: none;
    background: none;
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.recap-progression-text {
    font-weight: 900;
    font-size: 1.15rem;
}