/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// Page scss
@import "./tenue/saisieUniverselle/saisie-style.scss";

.cursor-default {
  cursor: default;
}

.pointer {
  cursor: pointer;
}
.pointer-disabled {
  cursor: not-allowed;
}

.loading-indicator:before {
  content: "";
  background: #fffafa;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.5;
}
.loading-indicator:after {
  content: "";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  opacity: 0.5;
}

@-moz-document url-prefix() {
  /* For Firefox */
  body.modal-open {
    overflow: hidden;
  }
}

@supports (-ms-ime-align: auto) {
  /* For IE and Edge */
  body.modal-open {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  body.modal-open::-webkit-scrollbar {
    display: none;
  }
}
body.modal-open {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body.modal-open::-webkit-scrollbar {
  display: none;
}

.form-col {
  height: 100%;
  padding-top: 20px;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.grid-container > button {
  text-align: center;
  font-size: 10px;
}
.rs-table-header-row-wrapper {
  z-index: 0 !important;
}
.bg-theme {
  background-color: #fff;
}

.dark-layout {
  #home-cabinet {
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      border-color: rgb(92 94 95 / 41%) !important;
    }
  }

  .react-dataTable {
    div:not(.rdt_TableHead div) {
      background-color: #283046;
    }
  }
  .bg-theme {
    background-color: #283046;
  }
  .cfrniX,
  .jMtvTm,
  .kUGdPe {
    .sc-kgTSHT,
    .BafWS {
      color: #d0d2d6;
      background-color: #283046;
    }
    background-color: #283046;
  }
  .MuiTableCell-root.MuiTableCell-body {
    color: #d0d2d6;
  }
  .dwPlXY,
  .sc-iveFHk.bzRnkJ,
  .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded,
  .swal2-container.swal2-center > .swal2-popup {
    color: #d0d2d6;
    background-color: #283046;
  }
  .dDxFAw,
  .gWznfd,
  .jqyjmj {
    background-color: #283046;
  }
  .gKbhqU,
  .tree-title,
  .list-group-item:not(.list-group-item.active) {
    color: #d0d2d6;
    background-color: #283046;
  }
  .dHNCsd {
    background-color: #283046;
  }
  .fyrdjl {
    color: rgba(255, 255, 255, 0.54);
    fill: rgba(255, 255, 255, 0.54);
    &:disabled {
      color: rgba(255, 255, 255, 0.18);
      fill: rgba(255, 255, 255, 0.18);
    }
  }
}
#rubrique-body {
  .sc-eDWCr.hbotQl:not(.sc-eDWCr.hbotQl[data-column-id="1"]) {
    width: 100%;
    text-align: center;
  }
  .sc-fLcnxK.dApqnJ {
    min-height: 500px;
  }
  .dwPlXY {
    font-size: 12px;
  }
  .iSAVrt {
    font-size: 12px;
  }
  div[data-tag="allowRowEvents"]:not(
      div[data-tag="allowRowEvents"]:first-child
    ) {
    padding: 0;
  }
}
#add-formule > :last-child:focus-visible {
  outline: #1ab394 1px solid;
}

@media (min-width: 576px) {
  .modal-slide-in #rubric-modal.modal-dialog.sidebar-lg {
    width: 66rem;
  }
  #rubric-modal {
    .modal-title {
      width: 100%;
      text-align: center;
    }
  }
}
#calendar-filter {
  td {
    cursor: pointer;
    text-align: center;
  }
}

// #general-state{
//   .table-responsive{
//     height: 100%;
//   }
// }

#home-cabinet {
  .MuiTableCell-body {
    font-size: 12px !important;
  }
  .MuiTableCell-root {
    padding: 5px 16px;
  }
  .MuiTableCell-alignLeft {
    text-align: center;
  }
  .rdt_TableHeadRow {
    .sc-eDWCr.hbotQl {
      white-space: normal;
    }
  }
}

.tree-title {
  margin: 0px;
  font-size: 18px;
  padding: 12px 0px;
  position: sticky;
  background: #ffffff;
  top: 0px;
  z-index: 2;
}

.check-list-setting {
  .modal-title {
    text-align: center;
    width: 100%;
  }
}

.vertical-layout .main-menu .navigation a.active {
  box-shadow: 0 0 0 0 #ffffff !important;
}

.th-custom {
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-color: #82828291;
  border-style: solid;
  border-width: 1px;
  padding: 5px 10px !important;
  color: #ffffff !important;
  text-align: center !important;
}

.custom-pagination {
  ul {
    margin: 0px !important;
  }
}

.popover {
  max-width: none !important;
  // .popover-body{
  //   width: 500px !important;
  // }
}

.date-grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #2196f3;
}

.list-group-item.active {
  z-index: 0 !important;
}
button.MuiButtonBase-root{
  position: static
}
@media (min-width: 768px){
  .col-md-1-5 {
    width: 14.66666667% !important;
  }
  .col-md-1-4 {
    width: 10.333333% !important;
  }
  .col-md-0-5 {
    width: 7.333333% !important;
  }
  .col-md-0-7 {
  width: 11.333333% !important;
  }
}

.header-navbar.floating-nav{
  z-index: 99;
}